import React, { useState, useEffect } from 'react';
import { useGames } from '../hooks/useGames';
import GameCard from '../components/games/GameCard';
import { motion } from 'framer-motion';
import { SparklesIcon, ArrowTrendingUpIcon, FireIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import ChatWindow from '../components/ChatWindow/ChatWindow';
import GameOverview from '../components/GameOverview/GameOverview';
import { db } from '../services/firebase';
import { doc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

const GamesTest = () => {
  const { games, loading, error } = useGames();
  const { user } = useAuth();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isOverviewOpen, setIsOverviewOpen] = useState(false);
  // For NFL games, the date format is different. (Using game.date.timestamp * 1000)
  const sortedGames = [...games].sort((a, b) => {
    const dateA = new Date(a.date?.timestamp ? a.date.timestamp * 1000 : a.date);
    const dateB = new Date(b.date?.timestamp ? b.date.timestamp * 1000 : b.date);
    return dateA - dateB;
  });
  
  const openAffiliateSignup = () => {
    window.open('https://strategixai.getrewardful.com/signup', '_blank');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#0B1120] flex justify-center items-center">
        <div className="relative">
          {/* Enhanced loading animation */}
          <motion.div 
            className="w-32 h-32 relative"
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          >
            <div className="absolute inset-0 rounded-full border-4 border-indigo-500/20" />
            <motion.div 
              className="absolute inset-0 rounded-full border-t-4 border-indigo-500"
              animate={{ rotate: 360 }}
              transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
            />
            <motion.div 
              className="absolute inset-4 rounded-full border-t-4 border-purple-500"
              animate={{ rotate: -360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            />
            <motion.div 
              className="absolute inset-8 rounded-full border-t-4 border-blue-500"
              animate={{ rotate: 360 }}
              transition={{ duration: 2.5, repeat: Infinity, ease: "linear" }}
            />
          </motion.div>
          <motion.div 
            className="absolute inset-0 bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-blue-500/20 blur-xl"
            animate={{ 
              opacity: [0.5, 1, 0.5],
              scale: [0.8, 1.1, 0.8]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#0B1120] flex justify-center items-center p-4">
        <motion.div 
          className="backdrop-blur-sm bg-red-500/10 border border-red-500/30 rounded-2xl p-6 max-w-md w-full"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="text-red-400 text-center">
            <h3 className="text-xl font-semibold mb-2">Error Loading Games</h3>
            <p>{error.message}</p>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <motion.div 
      className="min-h-screen bg-[#0B1120]"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Utilities Banner */}
      <motion.div 
        className="bg-[#0B1120] border-b border-white/[0.05]"
        variants={itemVariants}
      >
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-3">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
            <motion.div 
              className="flex items-center gap-2 bg-[#111827] px-4 py-2 rounded-xl w-full sm:w-auto"
              whileHover={{ scale: 1.02 }}
            >
              <ArrowTrendingUpIcon className="w-4 h-4 text-green-400" />
              <span className="text-green-400 text-sm">Last 7 Days: NBA Win Rate: 74.8%</span>
            </motion.div>

            <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-3 sm:gap-4">
              <motion.button
                onClick={openAffiliateSignup}
                className="flex items-center justify-center gap-2 bg-emerald-500 px-4 py-2 rounded-xl text-sm text-white
                  hover:bg-emerald-600 transition-all w-full sm:w-auto"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <SparklesIcon className="w-4 h-4" />
                <span>Become an Affiliate</span>
              </motion.button>

              <motion.a
                href="https://prizepicks.onelink.me/FjtC/te8p1tcc"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col sm:flex-row items-center justify-center gap-1 sm:gap-2 bg-indigo-500 px-4 py-2 rounded-xl text-sm text-white
                  hover:bg-indigo-600 transition-all w-full sm:w-auto"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-center gap-2">
                  <img 
                    src="/images/sportsbooks/prizepicks.png"
                    alt="PrizePicks" 
                    className="w-4 h-4 object-contain"
                  />
                  <span>Join PrizePicks</span>
                </div>
                <span className="text-xs text-indigo-200 sm:hidden">Best for Player Props</span>
              </motion.a>

              <motion.a
                href="https://sportsbook.draftkings.com/r/sb/mykelandrewstanley/US-NC-SB"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col sm:flex-row items-center justify-center gap-1 sm:gap-2 bg-[#111827] px-4 py-2 rounded-xl text-sm text-white
                  hover:bg-[#1a2234] transition-all w-full sm:w-auto"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-center gap-2">
                  <img 
                    src="/images/sportsbooks/draftkings.png"
                    alt="DraftKings" 
                    className="w-4 h-4 object-contain brightness-200"
                  />
                  <span>Join DraftKings</span>
                </div>
                <span className="text-xs text-gray-400 sm:hidden">Instant Betslip Access</span>
              </motion.a>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Top Player Props Banner */}
      <motion.div 
        className="bg-gradient-to-r from-violet-500/[0.03] via-violet-500/[0.08] to-violet-500/[0.03]"
        variants={itemVariants}
      >
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <Link
            to="/top-picks"
            className="block"
          >
            <motion.div 
              className="bg-[#111827] rounded-2xl p-6 border border-violet-500/20 hover:border-violet-500/40 
                transition-all cursor-pointer relative overflow-hidden group"
              whileHover={{ scale: 1.01 }}
            >
              {/* Background Glow Effect */}
              <div className="absolute inset-0 bg-gradient-to-r from-violet-500/0 via-violet-500/10 to-violet-500/0 
                translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />
              
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-3">
                  <FireIcon className="w-5 h-5 text-violet-400" />
                  <h2 className="text-lg sm:text-xl font-semibold text-white">Today's Highest Probability Player Props</h2>
                </div>
                <div className="space-y-3">
                  <p className="text-sm text-gray-300">
                    Our A.I. analyzes every player's individual stat lines (points, rebounds, assists) and only shows predictions that have been correct 70%+ of the time historically, combined with a current 60%+ confidence rating for today's matchup.
                  </p>
                  <div className="bg-violet-500/10 border border-violet-500/20 rounded-xl p-3">
                    <p className="text-sm font-medium text-violet-400">
                      🎉 Special Announcement: Free Access Until NHL & MLB Roll-Out! We are finalizing the backtesting, ensuring the Picks are 65+% accross all props!
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2 text-violet-400 font-medium">
                  <span>View Top Picks</span>
                  <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>
            </motion.div>
          </Link>
        </div>
      </motion.div>

      {/* Games Grid */}
      <div className="relative max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <motion.div 
          className="grid grid-cols-1 lg:grid-cols-2 gap-8"
          variants={containerVariants}
        >
          {sortedGames.map((game, index) => (
            <motion.div
              key={game.id}
              variants={itemVariants}
              className="group relative transform perspective-1000"
              custom={index}
            >
              {/* Card Glow Effect */}
              <motion.div 
                className="absolute -inset-[1px] bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 
                  rounded-2xl blur-lg group-hover:blur-xl transition-all duration-300 opacity-0 group-hover:opacity-100"
                animate={{ 
                  opacity: [0, 0.5, 0],
                  scale: [0.95, 1.05, 0.95]
                }}
                transition={{ duration: 4, repeat: Infinity }}
              />
              
              {/* Card Container */}
              <motion.div 
                className="relative bg-[#0B1120]/90 backdrop-blur-xl rounded-2xl border border-white/[0.05] overflow-hidden transform-gpu transition-transform duration-300 hover:-translate-y-1"
                whileHover={{ scale: 1.02 }}
              >
                <GameCard game={game} />
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Modals / Portals */}
      {isChatOpen && user && createPortal(
        <ChatWindow game={sortedGames[0]} onClose={() => setIsChatOpen(false)} />,
        document.getElementById('modal-root')
      )}
      {isOverviewOpen && user && createPortal(
        <GameOverview game={sortedGames[0]} onClose={() => setIsOverviewOpen(false)} />,
        document.getElementById('modal-root')
      )}
    </motion.div>
  );
};

export default GamesTest;
